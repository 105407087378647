import axios from 'axios'
import rpc from '@/helpers/auth/rpc'
const contractName = process.env.VUE_APP_CONTRACT_NAME
export default {
  async ACT_PHASES_4_TABLES({commit}, {sa = null, table = '', lower_bound = '', upper_bound = ''}) {
    try {
      const _table_filter = ['equipments', 'jars']
      commit('MUT_PHRASES_GAME', false)
      const data = {
        json: true,
        lower_bound: lower_bound ? lower_bound: '',
        upper_bound: upper_bound ? upper_bound: '',
        key_type: 'name',
        table: table || 'slots',
        index_position: table === 'jars' ? 2 : 1,
        code: contractName,
        scope: contractName,
      }
      data.limit = 100
      const res = await rpc.get_table_rows(data)
      commit('MUT_TABLES_PHASES_4', {
        rows: _table_filter.includes(table) ? res.rows.filter(ls => ls.owner === sa): res.rows,
        table
      })
    } catch (e) {
      console.log(e);
      commit('MUT_PHRASES_GAME', true)
    }
  },
}