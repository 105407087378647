const areas = [
  {
    id: 0,
    title: "gold",
    coords: [169, 691, 167],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine3.png',
    shema: 'mine',
    id_resource: 3,
    name_resource: 'gold',
    quantity: '100.0000',
    time_reset: 720,
    modal: true
  },
  {
    id: 1,
    title: "guns shops",
    coords: [439, 478, 124],
    shape: "circle",
    link: '',
    link_href: 'https://test.neftyblocks.com/c/goldenhills1/blends',
    modal: false
  },
  {
    id: 2,
    title: "saloon",
    coords: [628, 214, 118],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine2.png',
    shema: 'mine' ,
    id_resource: 2,
    name_resource: 'bourbon',
    quantity: '50.0000',
    time_reset: 480,
    modal: true
  },
  {
    id: 3,
    title: "town hall",
    coords: [977, 586, 240],
    shape: "circle",
    link: {name: 'gamePage'},
    modal: false
  },
  {
    id: 4,
    title: "bank",
    coords: [1253, 220, 140],
    shape: "circle",
    link: '',
    modal: false
  },
  {
    id: 5,
    title: "sherif office",
    coords: [1629, 378, 129],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine4.png',
    shema: 'mine',
    id_resource: 4,
    name_resource: 'license',
    quantity: '500.0000',
    time_reset: 1440,
    modal: true
  },
  {
    id: 6,
    title: "farm",
    coords: [1295, 861, 126],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine1.png',
    shema: 'mine',
    id_resource: 1,
    name_resource: 'food',
    quantity: '25.0000',
    time_reset: 720,
    modal: true
  },
  {
    id: 7,
    title: "travel",
    coords: [1765, 853, 85],
    shape: "circle",
    modal: false,
    link: {name: 'gamePage'},
  },
  {
    id: 8,
    title: "choice",
    coords: [243, 289, 69],
    shape: "circle",
    modal: false,
    link: {name: 'gamePage'},
  },
]

export default areas