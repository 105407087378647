import rpc from '@/helpers/auth/rpc'
const contractName = process.env.VUE_APP_CONTRACT_NAME
export default {
  async ACT_GET_TABLE_TRAVEL({commit}, {sa = null, table = null, lower_bound = false}) {
    try {
      if(sa && table) {
        let rpc_data = {
          json: true,
          code: contractName,
          scope: contractName,
          table: table || '',
          index_position: 2,
          key_type: 'name',
          lower_bound: lower_bound ? sa : '',
          upper_bound: sa,
          limit: 100,
          reverse: false,
          show_payer: false,
        }
        const list = await rpc.get_table_rows(rpc_data)
        commit('MUT_USER_LIST_TRAVEL', {table, data: list.rows || []})
      }
    } catch (e) {
      console.log(e);
      commit('MUT_ERROR_GAME', e)
    }
  },
  async ACT_GET_TABLE({commit}, {sa = null, table = null, lower_bound = false}) {
    try {
      if(sa && table) {
        let rpc_data = {
          json: true,
          code: contractName,
          scope: contractName,
          table: table || '',
          index_position: table === 'groups' ? 3: 1,
          key_type: 'name',
          lower_bound: lower_bound ? sa : '',
          upper_bound: sa ? sa : '',
          limit: 100,
          reverse: false,
          show_payer: false,
        }
        const list = await rpc.get_table_rows(rpc_data)
        commit('MUT_USER_LIST', {table, data: list.rows || []})
      }
    } catch (e) {
      console.log(e);
      commit('MUT_ERROR_GAME', e)
    }
  },
  ACT_CLEAR_CREATOR({commit}) {
    commit('MUT_CLEAR_CREATOR', null)
  }
}