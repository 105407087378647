import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  user_list: [],
  user_character_card: [],
  error: false,
  balance: 0,
  user_stacking_power: [],
  user_rewards: 0,
  resource: null,
  resource_all_list: null
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}