export default {
  MUT_ERROR_GAME(state, data) {
    state.error = data
  },
  MUT_USER_LIST(state, {table = '', data = []}) {
    state.table[table] = data
    if(table === 'duels' && data.length) {
      state.creator_duels = data[0]
    }
  },
  MUT_USER_LIST_TRAVEL(state, {table = '', data = []}) {
    state.table_travel[table] = data
  },
  MUT_CLEAR_CREATOR(state, data) {
    state.creator_duels = null
  }
}