<template>
   <el-row type="flex" justify="space-between" align="middle" v-if="!isShowFooter">
     <el-col :sm="18" :xs="18" :lg="18" :xl="18">
       <div class="__item-block-footer">
         <itemResource
             :time="timeFinish(2)"
             :img="`/img/app/bg/footer/_bourbon.png`"
             :value="resource('bourbon')"
         ></itemResource>
         <itemResource
             :time="timeFinish(1) "
             :img="`/img/app/bg/footer/food.png`"
             :value="resource('food')"
         ></itemResource>
         <itemResource
             :time="timeFinish(3) "
             :value="resource('gold')"
             :img="`/img/app/bg/footer/gold.png`"
         ></itemResource>
         <itemResource
             :time="timeFinish(4) "
             :value="resource('license')"
             :img="`/img/app/bg/footer/lecensia.png`"
         ></itemResource>
       </div>
     </el-col>
     <el-col :sm="6" :xs="6" :lg= "6" :xl="6">
       <div class="__item-block-footer">
         <itemSocial></itemSocial>
       </div>
     </el-col>
   </el-row>
</template>

<script>
import itemResource from '../Main/components/Footer/Resurce'
import itemSocial from '../Main/components/Footer/Social'
import {mapGetters} from "vuex";
export default {
  components: {
    itemResource,
    itemSocial
  },
  computed: {
    ...mapGetters('user', ['GET_USER_RESOURCE', 'GET_USER_LIST']),
    isShowFooter() {
      let name = ['characterPage', 'town hall']
      if(this.$route.query && this.$route.query.name) {
        return name.includes(this.$route.query.name)
      } else {
        return name.includes(this.$route.name)
      }

    },
    userList() {
      return this.GET_USER_LIST[0] || null
    }
  },
  methods: {
    calcTime(val = 0, offset = 0) {
      // val-sec offset-min
      const date_now = Date.now()
      const time_ms = val * 1000
      const offset_ms = offset * 60 * 1000
      const time = (time_ms + offset_ms) - date_now
      return time <=0 ? 0 : time
    },
    timeFinish(val) {
      switch (val) {
        case 1:
          return this.calcTime(this.userList?.mined_at_1 || 0 , 240)
        case 2:
          return this.calcTime(this.userList?.mined_at_2  || 0 , 480)
        case 3:
          return this.calcTime(this.userList?.mined_at_3 || 0 , 720)
        case 4:
          return this.calcTime(this.userList?.mined_at_4 || 0 , 1440)
      }
    },
    resource(val) {
      return this.GET_USER_RESOURCE ? this.GET_USER_RESOURCE[val.toLowerCase()] : 0
    }
  }
}
</script>