import rpc from '@/helpers/auth/rpc'
const contractName = process.env.VUE_APP_CONTRACT_NAME
export default {
  ACT_RESET_DATA_GROUP_DUELS({commit}) {
    commit('MUT_RESET_DATA_GROUP_DUELS')
  },
  async ACT_DUELS_GROUP_OPEN_CHECK({commit}, {lower_bound = 0, upper_bound = 0, limit = 1000}) {
    try {
      const data = {
        json: true,
        table: 'groups',
        limit: limit,
        lower_bound: lower_bound,
        upper_bound: upper_bound,
        reverse: false,
        show_payer: false,
        code: contractName,
        scope: contractName,
      }
      const res = await rpc.get_table_rows(data)
      return res.rows || []
    } catch (e) {
      console.log(e);
    }
  },
  async ACT_USERS_IS_GROUPS_DUELS({commit}, {id_groups}) {
    try {
      const result = await rpc.get_table_rows({
        json: true,
        code: contractName,
        scope: contractName,
        table: 'groupusers',
        limit: 2,
        index_position: 2,
        key_type: 'i64',
        lower_bound: id_groups,
        upper_bound: id_groups,
        reverse: false,
        show_payer: false,
      })
      return result.rows || []
    } catch (e) {
      console.log(e);
    }
  },
  async ACT_DUELS_GROUP_OPEN({commit}, {lower_bound = 0, upper_bound = 0, limit = 1000}) {
    try {
      const data = {
        json: true,
        table: 'groups',
        limit: limit,
        index_position: 3,
        key_type: 'i64',
        lower_bound: lower_bound,
        upper_bound: upper_bound,
        reverse: false,
        show_payer: false,
        code: contractName,
        scope: contractName,
      }
      const res = await rpc.get_table_rows(data)
      commit('MUT_DUELS_GROUP_OPEN', res.rows || [])
    } catch (e) {
      console.log(e);
    }
  },
  ACT_DUELS_GROUP_USERS_NEXT_ENEMY({commit}, data = null) {
    try {
      commit('MUT_DUELS_GROUP_USERS_NEXT_ENEMY', data)

    } catch (e) {
      console.log(e);
    }
  },
  ACT_DUELS_GROUP_USERS({commit}, data) {
    try {
      commit('MUT_DUELS_GROUP_USERS', data || [])
    } catch (e) {
      console.log(e);
    }
  },
  ACT_DUELS_GROUP_LOGS({commit}, data) {
    commit('MUT_DUELS_GROUP_LOGS', data)
  },
  ACT_DUELS_GROUP_LOGS_FINALLY({commit}, data) {
    commit('MUT_DUELS_GROUP_LOGS_FINALLY', data)
  },
  ACT_DUELS_GROUP_USERS_UPDATE({commit} , data) {
    commit('MUT_DUELS_GROUP_USERS_UPDATE', data)
  }
}