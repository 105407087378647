const areas1535_864 = [
  {
    id: 0,
    id_page: 8,
    target: "",
    alt: "arena",
    title: "arena",
    href: "",
    coords: [194,229,73],
    shape: "circle",
    link: {name: 'gamePage'},
    modal: false,
  },
  {
    id: 1,
    target: "",
    alt: "guns shops",
    title: "guns shops",
    href: "",
    coords: [348,368,104],
    shape: "circle",
    link: '',
    link_href: 'https://test.neftyblocks.com/c/goldenhills1/blends',
    modal: false
  },
  {
    id: 2,
    target: "",
    alt: "gold",
    title: "gold",
    href: "",
    coords: [140,593,135],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine3.png',
    shema: 'mine',
    id_resource: 3,
    name_resource: 'gold',
    quantity: '100.0000',
    time_reset: 720,
    modal: true
  },
  {
    id: 3,
    target: "",
    alt: "saloon",
    title: "saloon",
    href: "",
    coords: [498,163,85],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine2.png',
    shema: 'mine' ,
    id_resource: 2,
    name_resource: 'bourbon',
    quantity: '50.0000',
    time_reset: 480,
    modal: true
  },
  {
    id: 4,
    target: "",
    alt: "bank",
    title: "bank",
    href: "",
    coords: [1005,169,98],
    shape: "circle",
    link: '',
    modal: false
  },
  {
    id: 5,
    id_page: 3,
    target: "",
    alt: "town hall",
    title: "town hall",
    href: "",
    coords: [747,440,236],
    shape: "circle",
    link: {name: 'gamePage'},
    modal: false
  },
  {
    id: 6,
    target: "",
    alt: "sherif office",
    title: "sherif office",
    href: "",
    coords: [1308,280,96],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine4.png',
    shema: 'mine',
    id_resource: 4,
    name_resource: 'license',
    quantity: '500.0000',
    time_reset: 1440,
    modal: true
  },
  {
    id: 7,
    target: "",
    alt: "shop",
    title: "shop",
    href: "",
    coords: [1421,145,59],
    shape: "circle",
    link: '',
    link_href: 'https://test.neftyblocks.com/c/goldenhills1/blends',
    modal: false
  },
  {
    id: 8,
    target: "",
    alt: "farm",
    title: "farm",
    href: "",
    coords: [1058,674,112],
    shape: "circle",
    image: '/img/app/components/modal/mine/mine1.png',
    shema: 'mine',
    id_resource: 1,
    name_resource: 'food',
    quantity: '25.0000',
    time_reset: 720,
    modal: true
  },
  {
    id: 9,
    id_page: 7,
    target: "",
    alt: "travel",
    title: "travel",
    href: "",
    coords: [1415,677,57],
    shape: "circle",
    modal: false,
    link: {name: 'gamePage'},
  }
]

export default areas1535_864