<template>
  <div class="__auth-layout">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "Auth.vue"
}
</script>