<template>
  <div class="__resource-footer-item">
    <div class="__wrap__footer-main-res-image" :style="{'background-image': `url(${img})`}"></div>
    <div class="__resource-block-items">
      <div class="__block-wrap-number">
        <div class="__icon">
          <img class="icon" src="/img/app/icons/icon_watch.png" alt="">
        </div>
        <div class="__number">
          <vCountdownTimer
            :daysShow="false"
            :time="timeComputed"
          ></vCountdownTimer>
        </div>
      </div>
      <div class="__block-number-1 text-left">{{value}}</div>
    </div>
  </div>
</template>

<script>
import vCountdownTimer from '@/components/Game/vCountdownTimer'
export default {
  props: {
    time: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    img: {
      type: String,
      default: ''
    }
  },
  components: {
    vCountdownTimer
  },
  computed: {
    timeComputed() {
      return !this.time || this.time < 0 ? 0 : +this.time
    },
  }
}
</script>
