import rpc from '@/helpers/auth/rpc'
// import link from '@/helpers/auth/anchor-link'
import {sumBy, sum, flattenDeep} from 'lodash'
import axios from 'axios'

const contractName = process.env.VUE_APP_CONTRACT_NAME
const collectionName = process.env.VUE_APP_COLLECTION_NAME
const ghcoinContractName = process.env.VUE_APP_GHCOIN_CONTACT_NAME
const URL = process.env.VUE_APP_API_ENDPOINT
const shemaName = 'resource'
const currency = 'GHCOIN'

const defaultTableData = {
  json: true,
  limit: 1,
  reverse: false,
  show_payer: false,
}

const resource_calc = (arr) => {
  let counts  = {};
  (arr.map(ls => ls.name.toLowerCase())).forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
  return counts
}


export default {
  async ACT_USER_BALANCE({commit}, userAccount = null) {
    try {
      if (userAccount) {
        const res = await rpc.get_currency_balance(ghcoinContractName, userAccount, currency)
        commit('MUT_USER_BALANCE', res.length ? res[0] : 0)
      }
    } catch (e) {
      console.warn(e);
    }
  },
  async ACT_USER_CHECK({commit, dispatch}, userAccount = null) {
    try {
      const user = await rpc.get_table_rows({
        code: contractName,
        scope: contractName,
        table: 'users',
        lower_bound: userAccount,
        upper_bound: userAccount,
        ...defaultTableData
      })
      if (user.rows.length) {
        commit('MUT_USER_LIST', user.rows)
      }
    } catch (e) {
      console.warn(e)
      commit('MUT_USER_ERROR', true)
    }
  },
  async ACT_USER_RESOURCE({commit}, sa) {
    try {
      const resource = await axios.get(`${URL}/assets?collection_name=${collectionName}&schema_name=${shemaName}&owner=${sa}&limit=1000`)
      if (resource?.data?.data) {
        const resource_user = resource_calc(resource?.data?.data)
        commit('MUT_USER_RESOURCE', resource_user)
        commit('MUT_USER_RESOURCE_ALL', resource?.data?.data || null)
      }
    } catch (e) {
      console.log(e);
    }
  },
  async ACT_USER_STACKING_POWER({commit}, sa) {
    try {
      const data = {
        lower_bound: sa,
        upper_bound: sa,
        key_type: 'name',
        table: 'stakedassets',
        index_position: 2,
        code: contractName,
        scope: contractName,
        ...defaultTableData
      }
      data.limit = 1000000
      const res = await rpc.get_table_rows(data)
      const staking_power = sumBy(res.rows, 'power')
      commit('MUT_USER_STACKING_POWER', staking_power || 0)
      let val = []
      let now = Date.now() / 1000
      res.rows.forEach(ls => {
        //rewards
        let difference = (now - (+ls.claimed_at)) / 60
        let power = (+ls.power / 1440 * difference) * 0.9
        val.push(+power)
      })
      let rewards = sum(val)
      commit('MUT_USER_REWARDS', rewards || 0)
    } catch (e) {
      console.log(e);
    }
  },
  async ACT_USER_RESOURCE_CHARACTER_POTION({commit}, {sa = null, schema = null, collection = null}) {
    try {
      if (sa && collection && schema) {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
        const schema_all = ['chest', 'jar', 'boost']
        const promises = []
        schema_all.forEach(ls => {
          promises.push(axios.get(`${URL}/assets?collection_name=${collection}&schema_name=${ls}&owner=${sa}&limit=1000`))
        })
        Promise.all([...promises]).then((res) => {
          let resource_user = flattenDeep(res.map(ls => [...ls.data.data]))
          if (resource_user && resource_user.length) {
            commit('MUT_USER_RESOURCE_LIST_CARD', resource_user || [])
          } else {
            commit('MUT_USER_RESOURCE_LIST_CARD', [])
          }
        }).catch((e) => {
          console.log(e);
        })

      } else {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
      }
    } catch(e) {
      console.log(e);
    }
  },
  async ACT_USER_RESOURCE_CHARACTER({commit}, {sa = null, schema = null, collection = null}) {
    try {
      if(sa && collection && schema) {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
        const resource = await axios.get(`${URL}/assets?collection_name=${collection}&schema_name=${schema}&owner=${sa}&limit=1000`)
        if(resource?.data?.data) {
          const resource_user = resource?.data?.data
          commit('MUT_USER_RESOURCE_LIST_CARD', resource_user || [])
        }
      } else {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
      }

    } catch (e) {
      console.log(e);
    }
  },
  async ACT_USER_RESOURCE_CHARACTER_STACKING({commit}, {sa = null, schema = null, collection = null}) {
    try {
      if(sa && collection && schema) {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
        const data = {
          lower_bound: sa,
          upper_bound: sa,
          key_type: 'name',
          table: schema,
          index_position: 2,
          code: contractName,
          scope: contractName,
          ...defaultTableData
        }
        data.limit = 1000000
        if(schema === 'staking') data.table = 'stakedassets'
        Promise.all([
          await axios.get(`${URL}/assets?collection_name=${collection}&schema_name=${schema}&owner=${sa}&limit=1000`),
          await rpc.get_table_rows(data)
        ]).then((res) => {
          const res_api = (res[0]?.data?.data || []).map(ls => {
            let _ls = {}
            if(ls.schema && ls.schema.schema_name) {
              _ls = {
                ...ls,
                _is_api: true
              }
            } else {
              _ls = {
                ...ls,
                _is_api: true,
                schema: {
                  schema_name: schema
                }
              }
            }
            return _ls
          })
          const res_table = (res[1]?.rows || []).map(ls => {
            let _ls = {}
            if(ls.schema && ls.schema.schema_name) {
              _ls = {
                ...ls,
                _is_api: true
              }
            } else {
              _ls = {
                ...ls,
                _is_table: true,
                schema: {
                  schema_name: schema
                }
              }
            }
            return _ls
          })
          const _res_result = [...res_api, ...res_table]
          commit('MUT_USER_RESOURCE_LIST_CARD', _res_result || [])
        })

      } else {
        commit('MUT_USER_RESOURCE_LIST_CARD', [])
      }
    } catch (e) {
      console.log(e);
    }
  }
}