export default {
  GET_GAME_ERROR:state=>state.error,
  GET_DUELS_GROUP_OPEN:state=>state.duels_group_open,
  GET_DUELS_GROUP_USERS:state=>state.duels_group_open_data || [],
  GET_DUELS_GROUP_LOGS:state=>state.duels_group_logs,
  GET_DUELS_GROUP_LOGS_LAST:state=>state.duels_group_logs_finally,
  GET_DUELS_GROUP_KICK_RESULTS:state=>state.kick_duels_group_results,
  GET_DUELS_GROUP_CREATED(state) {
    if(state.duels_group_open_data) {
      if(state.duels_group_open_data && state.duels_group_open_data.length && state.duels_group_open_data[0].user) {
        return state.duels_group_open_data[0].user || ''
      } else {
        return ''
      }
    } else {
      return null
    }
  },
  GET_DUELS_GROUP_USERS_NEXT_ENEMY:state=>state.duels_group_users_next_enemy
}