import rpc from '@/helpers/auth/rpc'
const contractName = process.env.VUE_APP_CONTRACT_NAME

const defaultTableData = {
  json: true,
  limit: 100,
  reverse: false,
  show_payer: false,
}

export default {
  async ACT_SETTING_LEVEL({commit}) {
    try {
      commit('MUT_SETTING_ERROR', false)
      const res = await rpc.get_table_rows({
        code: contractName,
        scope: contractName,
        table: 'levels',
        ...defaultTableData
      })
      commit('MUT_SETTING_LEVEL', res.rows || [])
    } catch (e) {
      console.warn(e);
      commit('MUT_SETTING_ERROR', true)
    }
  },
  async ACT_SETTING_SLOTS({commit}) {
    try {
      commit('MUT_SETTING_ERROR', false)
      const res = await rpc.get_table_rows({
        code: contractName,
        scope: contractName,
        table: 'slots',
        ...defaultTableData
      })
      commit('MUT_SETTING_SLOTS', res.rows || [])
    } catch (e) {
      console.warn(e);
      commit('MUT_SETTING_ERROR', true)
    }
  },
}