export const test_data = {
  "test_1": {
    users: [
      {
        "_test": true,
        "id": 120,
        "groupId": 397,
        "user": "waxisoroka41",
        "name": "test_new",
        "level": 1,
        "command": 1,
        "position": 3,
        "enemy": 3,
        "enemys": [3, 4, 5, 1, 2],
        "hp": 120,
        "hpMax": 120,
        "hpReg": 8,
        "fightEndAt": 1675963950,
        "damageMin": 8,
        "damageMax": 14,
        "armorMinHead": 0,
        "armorMaxHead": 0,
        "armorMinBody": 0,
        "armorMaxBody": 0,
        "armorMinGroin": 0,
        "armorMaxGroin": 0,
        "armorMinFeet": 0,
        "armorMaxFeet": 0,
        "crit": 12,
        "dodge": 6,
        "crit_block": 8,
        "dodge_block": 4,
        "potionsUsed": [],
        "injuryType": 0,
        "status": 0,
        "socketId": "GlFqekzUa01EMgIFAAAS",
        "time": 1676910079,
        "totalDamage": 0,
        "timerId": 4251
      },
      {
        "_test": true,
        "id": 117,
        "groupId": 397,
        "user": "gaiduchini33",
        "name": "Don Gaiduchini",
        "level": 1,
        "command": 2,
        "position": 3,
        "enemy": 3,
        "enemys": [3, 4, 5, 1, 2],
        "hp": 160,
        "hpMax": 160,
        "hpReg": 8,
        "fightEndAt": 1676906281,
        "damageMin": 13,
        "damageMax": 22,
        "armorMinHead": 2,
        "armorMaxHead": 4,
        "armorMinBody": 2,
        "armorMaxBody": 4,
        "armorMinGroin": 2,
        "armorMaxGroin": 4,
        "armorMinFeet": 2,
        "armorMaxFeet": 4,
        "crit": 21,
        "dodge": 15,
        "crit_block": 14,
        "dodge_block": 10,
        "potionsUsed": [],
        "injuryType": 0,
        "status": 0,
        "socketId": "AVdTv8Z9yYxIEEcLAAAb",
        "time": 1676910079,
        "totalDamage": 0,
        "timerId": 4220
      }
    ]
  },
  "test_2" : {
    "account": "vova12345123",
    "enemy": {
      "armorMaxBody": 4,
      "armorMaxFeet": 4,
      "armorMaxGroin": 4,
      "armorMaxHead": 4,
      "armorMinBody": 2,
      "armorMinFeet": 2,
      "armorMinGroin": 2,
      "armorMinHead": 2,
      "command": 2,
      "crit": 21,
      "crit_block": 14,
      "damageMax": 16,
      "damageMin": 10,
      "dodge": 15,
      "dodge_block": 10,
      "enemy": 2,
      "enemys": [2, 3, 4, 5, 1],
      "length": 5,
      "fightEndAt": 1676906284,
      "groupId": 397,
      "hp": 135,
      "hpMax": 135,
      "hpReg": 8,
      "id": 116,
      "injuryType": 0,
      "level": 1,
      "name": "SonyaKlause",
      "position": 2,
      "potionsUsed": [],
      "socketId": "EaGdFdYCU1r413PNAAAM",
      "status": 0,
      "time": 1676910079,
      "timerId": 4210,
      "totalDamage": 0,
      "user": "sonyaklausee"
    }
  }
}



