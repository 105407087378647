<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters('auth', ['GET_USER']),
    ...mapGetters('user', ['GET_USER_LIST']),
    userData() {
      return this.GET_USER_LIST[0] || null
    },
    userSa() {
      return this.GET_USER && this.GET_USER.payload? this.GET_USER.payload.sa : null
      //return 'vova12345123'
    }
  },
  async mounted() {
    await this.$nextTick()
    await this.userAvatarImage()
    setInterval(async() => {
      await this.userState()
    },60000)
  },
  methods: {
    ...mapActions('user', ['ACT_USER_BALANCE', 'ACT_USER_STACKING_POWER', 'ACT_USER_RESOURCE', 'ACT_USER_CHECK']),
    ...mapActions('auth', ['ACT_AVATAR']),
    async userState() {
      await this.userCheck()
      await this.userBalance()
      await this.stackingPower()
      await this.resourceUser()
    },
    async userAvatarImage() {
      try {
        await axios.get(process.env.VUE_APP_AXIOS_URL + `/api/v1/owner/${this.userSa}`).then((res) => {
          this.ACT_AVATAR(res?.data?.user?.avatar || null)
        })
      } catch (e) {
        console.log(e);
      }
    },
    async userCheck() {
      await this.ACT_USER_CHECK(this.userSa)
    },
    async userBalance() {
      await this.ACT_USER_BALANCE(this.userSa)
    },
    async stackingPower() {
      await this.ACT_USER_STACKING_POWER(this.userSa)
    },
    async resourceUser() {
      await this.ACT_USER_RESOURCE(this.userSa)
    },
  }
}
</script>
