<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  data() {
    return {
      schema_active_btn: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['GET_USER']),
    ...mapGetters('user', ['GET_USER_RESOURCE', 'GET_USER_RESOURCE_CARD']),
    userSa() {
      return this.GET_USER && this.GET_USER.payload? this.GET_USER.payload.sa : null
      //return 'vova12345123'
    }
  },
  methods: {
    ...mapActions('user', [
      'ACT_USER_RESOURCE_CHARACTER',
      'ACT_USER_RESOURCE_CHARACTER_POTION',
      'ACT_USER_RESOURCE_CHARACTER_STACKING'
    ]),
    ...mapActions('game', ['ACT_GET_TABLE']),
    async userTable({table = null, lower_bound = false}) {
      try {
        if(this.GET_USER) {
          const userAccount = this.GET_USER.payload.sa
          //const userAccount = 'vova12345123'
          await this.ACT_GET_TABLE({
            sa: userAccount,
            table,
            lower_bound
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handlerCard({schema='', collection = process.env.VUE_APP_COLLECTION_NAME}) {
      this.schema_active_btn = schema
      try {
        this.loading_card = true
        const payload = {
          //sa: 'puzzlemazl11',
          sa: this.userSa,
          schema: schema ? schema : 'equipment',
          collection: collection
        }
        if(schema === 'potion') {
          await this.ACT_USER_RESOURCE_CHARACTER_POTION(payload)
          setTimeout(async () => {
            await this.prepareCharacterCard()
            this.loading_card = false
          }, 500)

        }
        if(schema === 'equipment'){
          await this.ACT_USER_RESOURCE_CHARACTER(payload)
          setTimeout(async () => {
            await this.prepareCharacterCard()
            this.loading_card = false
          }, 500)
        }
        if(schema === 'staking'){
          this.loading_card = false
          await this.ACT_USER_RESOURCE_CHARACTER_STACKING(payload)
          setTimeout(async () => {
            await this.prepareCharacterCard()
            this.loading_card = false
          }, 500)
        }
        if(schema === 'lands'){
          await this.ACT_USER_RESOURCE_CHARACTER_STACKING(payload)
          setTimeout(async () => {
            await this.prepareCharacterCard()
            this.loading_card = false
          }, 500)
        }
      } catch (e) {
        console.log(e);
        this.loading_card = false
      }
    },
    prepareCharacterCard() {
      this.loading_card = true
      this.card_character = []
      try {
        if(this.GET_USER_RESOURCE_CARD.length) {
          this.card_character = this.GET_USER_RESOURCE_CARD
          this.loading_card = false
        } else {
          this.card_character = []
          this.loading_card = false
        }
      } catch (e) {
        console.log(e);
        this.loading_card = false
      }
    },
    users_duels_group() {
      try {

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>
