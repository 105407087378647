export default {
  MUT_AVATAR(state, data) {
    state.user_avatar = data
  },
  MUT_AUTH_USER(state, {type = null, payload = null, session = null}) {
    state.user = {payload, session, type}
    state.is_auth = true
  },
  MUT_USER_LOGOUT(state) {
    state.user = null
    state.is_auth = false
  },
  MUT_AUTH_ERROR(state, data) {
    state.error = data
  },
  MUT_RESTORE_SESSION(state, data) {
    state.user.session = data
  }
}