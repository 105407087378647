import { createRouter, createWebHistory } from 'vue-router'
import authMiddleware from './middleware/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'authLayout'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views'),
  },
  {
    path: '/game',
    name: 'gameMain',
    meta: {
      name: 'Game Mame',
      layout: 'mainLayout',
      requiresAuth: true,
    },
    beforeEnter: [authMiddleware],
    beforeRouteUpdate: [authMiddleware],
    component: () => import(/* webpackChunkName: "about" */ '../views/game/index')
  },
  {
    path: '/game-page/:id',
    name: 'gamePage',
    meta: {
      name: 'Game Page',
      layout: 'gameLayout',
      requiresAuth: true,
    },
    beforeEnter: [authMiddleware],
    beforeRouteUpdate: [authMiddleware],
    component: () => import(/* webpackChunkName: "about" */ '../views/game/game-page.vue')
  },
  {
    path: '/character-page/:id',
    name: 'characterPage',
    meta: {
      name: 'Character Page',
      layout: 'gameLayout',
      requiresAuth: true,
    },
    beforeEnter: [authMiddleware],
    beforeRouteUpdate: [authMiddleware],
    component: () => import(/* webpackChunkName: "about" */ '../views/game/character')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
