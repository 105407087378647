<template>
  <div class="__wrapper-progress-header">
    <div
      v-if="this.userData || !isEmptyUserData"
      v-for="(progress, item) in customColor"
      :key="item"
      class="__inner-progress">
      <span class="__name-progress">{{progress?.name || ' - '}} </span>
      <el-progress
          :text-inside="true"
          :show-text="true"
          :stroke-width="15"
          status="exception"
          :percentage="progress.percentage > 100 ? 100 : progress.percentage || 0"
          :color="progress.color" >
          <span> {{progress.value + ' /'  + progress.value_max }}</span>
      </el-progress>
    </div>
  </div>
</template>

<script>
import {isEmpty} from 'lodash'
export default {
  props: {
    xpMaxLevel: {
      type: Number,
      default: 0
    },
    stamina: {
      type: Number,
      default: 0
    },
    userData: {
      type: Object,
      default: null
    }
  },
  computed: {
    isEmptyUserData() {
      return isEmpty(this.userData)
    },
    progressMax() {
      return (+this.userData.hp_max) + (+this.stamina * 5)
    },
    customColor () {
      return [
        {
          color: '#718F53',
          percentage: this.percentageValueHP(+this.userData.hp, this.progressMax),
          name: 'HP',
          value_max: this.progressMax,
          value: this.percentageValueHP(+this.userData.hp, this.progressMax)
        },
        {
          color: '#B49B5D',
          percentage: (+this.userData.xp / + this.xpMaxLevel ) *  100,
          name: 'XP',
          value_max: this.xpMaxLevel,
          value: +this.userData.xp || 0
        },
      ]
    }
  },
  methods: {
    percentageValueHP(hp, hp_max) {
      let _hp = hp + ((parseInt('' + Date.now() / 1000 / 60) - parseInt('' + this.userData.fight_end_at / 60)) * this.userData.hp_reg)
      return _hp > this.progressMax ? + this.progressMax : + _hp
     // return Math.round(+hp_max) > 100 ? 100 : Math.round(+hp)
    }
  }
}
</script>
<style lang="scss">
  .__wrapper-progress-header {
    width: 100%;
    margin-top: 10px;
    font-family: 'Futura PT Book';
    font-weight: bold;
    .__inner-progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .__name-progress {
        width: 20px;
      }
      span {
        width: 100%;
        font-size: 12px;
      }
      .el-progress {
        width: 100%;
        .el-progress__text {
          color: #ffffff;
        }
      }
      .el-progress-bar__outer {
        background-color: #3D342F;
        position: relative;
        .el-progress-bar__inner {
          display: flex;
          align-items: center;
          position: relative;
          .el-progress-bar__innerText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            span {
              width: 85px;
              font-size: 10px;
            }
          }
        }
      }
    }

  }
</style>