<script>
import {mapGetters, mapActions} from 'vuex'
import link from "@/helpers/auth/anchor-link";
const contractName = process.env.VUE_APP_CONTRACT_NAME
const collection_name = process.env.VUE_APP_COLLECTION_NAME
const accountMine = process.env.VUE_APP_GHCOIN_CONTACT_NAME
const currency = process.env.VUE_APP_CURRENCY
const stakeHandlerAccount = process.env.VUE_APP_STAKE_HANDLER_ACCOUNT
const ghcoinContractName = process.env.VUE_APP_GHCOIN_CONTACT_NAME
import handlersUser from '@/handlers/user/index'
export default {
  data() {
    return {
      session: null
    }
  },
  computed: {
    ...mapGetters('auth', [
      'GET_SESSION_USER',
      'GET_USER',
      'GET_IS_AUTH',
      'GET_USER_ERROR']),
    userSa() {
      return this.GET_USER && this.GET_USER.payload? this.GET_USER.payload.sa : null
      // return 'puzzlemazl11'
    },
    routerId() {
      return + this.$route.params.id || null
    },
    userAuth() {
      return this.GET_IS_AUTH && this.GET_USER
    },
  },
  mixins: [handlersUser],
  async mounted() {
    await this.$nextTick()
    if(!this.GET_SESSION_USER && this.$route.path !=='/') {
      await this.restoreSession()
    }
    if(this.routerId && this.routerId === 7) {
      await this.travelStatus()
    }
  },
  methods: {
    ...mapActions('game', ['ACT_GET_TABLE', 'ACT_GET_TABLE_TRAVEL', 'ACT_CLEAR_CREATOR']),
    ...mapActions('auth', ['ACT_RESTORE_SESSION', 'ACT_ANCHOR_AUTH']),
    ...mapActions('duels', ['ACT_DUELS_CLEAR_LOGS']),
    ...mapActions('socket', ['SOCKET_loadDuelUsers']),
    async endFightHandlerTransact({group_id = ''}) {
      try {
        if(group_id) {
          const action = {
            account: contractName,
            name: 'groupuserend',
            authorization: [this.GET_SESSION_USER],
            data: {
              group_id: parseInt(group_id),
              account: this.userSa,
            }
          }
          await link.transact({action}).catch((e) => {
            console.log({...e});
            throw e
          })
        }
      } catch (e) {
        console.log(e);
        throw e
      }
    },
    async cancelDuelsGroupTransact(val) {
      try {
        const action = {
          account: contractName,
          name: 'groupcancel',
          authorization: [this.GET_SESSION_USER],
          data: {
            group_id: parseInt(val.id)
          }
        }
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.ACT_DUELS_GROUP_OPEN( {lower_bound: 0, upper_bound: 0, limit: 1000})
            this.$forceUpdate()
          }, 500)
        })
      } catch (e) {
        console.log(e);
      }
    },
    async travelStatus() {
      try {
        const {payload} = this.GET_USER
        await this.ACT_GET_TABLE_TRAVEL({
          sa: payload.sa,
          table: 'travelspl',
          lower_bound: true
        })
      } catch (e) {
        console.log(e);
      }
    },
    async clameHandler() {
      try {
        if(!this.GET_SESSION_USER) {
          await this.restoreSession()
        }
        const {payload} = this.GET_USER
        const action = {
          account: contractName,
          name: 'claimall',
          authorization: [this.GET_SESSION_USER],
          data: {
            claimer: payload.sa,
            }
          }
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.userState()
            this.$forceUpdate(), 500
          })
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async restoreSession() {
      await this.ACT_ANCHOR_AUTH()
    },
    async stakeHandler(val) {
      this.loadingCard = true
      if(!this.GET_SESSION_USER) {
        await this.restoreSession()
      }
      const {payload} = this.GET_USER
      const action = {
        account: stakeHandlerAccount,
        name: 'transfer',
        authorization: [this.GET_SESSION_USER],
        data: {
          from: payload.sa,
          to: contractName,
          asset_ids: [val.asset_id],
          memo: '' + val.land_asset_id
        }
      }
      await link.transact({action}).then(async (res) => {
        setTimeout(async () => {
          await this.mineHandler({
            land_asset_id: val.land_asset_id
          })
          await this.userState()
          this.loadingCard = false
          this.$forceUpdate()
        }, 500)
      }).catch((e) => {
        console.log({...e});
        this.loadingCard = false
      })
    },
    async transactArea({shema, id_resource, quantity, time_reset, name_resource, title}) {
      const {payload} = this.GET_USER
      if(!this.GET_SESSION_USER) {
        await this.restoreSession()
      }
      const action = {
        account: accountMine,
        name: 'transfer',
        authorization: [this.GET_SESSION_USER],
        data: {
          from: payload.sa,
          to: contractName,
          quantity: quantity + ' ' + currency,
          memo: shema + id_resource
        }
      }
      await link.transact({action}).then(async (res) => {
        setTimeout(async () => {
          await this.userState()
          this.$forceUpdate()
          this.$router.go(0)
        , 500 })

      }).catch((e) => {
        console.log({...e});
      })
    },
    async transactUserAddContract({data}) {
      this.loading = true
      const {payload} = this.GET_USER
      if(!this.GET_SESSION_USER) {
        await this.restoreSession()
      }
      const action = {
        account: contractName,
        name: 'newuser',
        authorization: [this.GET_SESSION_USER],
        data: {
          user: payload.sa,
          ...data
        }
      }
      await link.transact({action}).then(async (res) => {
        await this.checkUser()
        this.loading = false
        this.$forceUpdate()
      }).catch((e) => {
        console.log({...e});
        this.loading = false
      })
    },
    async phase4Unstake(val) {
      try {
        const {payload} = this.GET_USER
        if(!this.GET_SESSION_USER) {
          await this.restoreSession()
        }
        const action = {
          account: contractName,
          name: 'removeitem',
          authorization: [this.GET_SESSION_USER],
          data: {
            asset_id: val.asset_id,
          }
        }
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.userState()
            this.$forceUpdate()
            this.$router.go(0) , 500 })
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e})
      }
    },
    async phase7PotionEquip({data = null}) {
      try {
        if(data) {
          const {payload} = this.GET_USER
          if(!this.GET_SESSION_USER) {
            await this.restoreSession()
          }
          const action = {
            account: stakeHandlerAccount,
            name: 'transfer',
            authorization: [this.GET_SESSION_USER],
            data: {
              from: payload.sa,
              to: contractName,
              asset_ids: [data.asset_id],
              memo: 'pocket'
            }
          }
          await this.transactPhase7({action})
        }

      } catch (e) {
        console.log(e);
      }
    },
    async phase1Unstake({data = null}) {
      try {
        if(data) {
          const {payload} = this.GET_USER
          if(!this.GET_SESSION_USER) {
            await this.restoreSession()
          }
          const action = {
            account: contractName,
            name: 'unstake',
            authorization: [this.GET_SESSION_USER],
            data: {
              staked_asset_id: data.staked_asset_id,
            }
          }
          await  this.transactPhase7({action})
        }
      } catch (e) {
        console.log({...e});
      }
    },
    async phase1Activate({data = null}) {
      try {
        if(data) {
          const {payload} = this.GET_USER
          if(!this.GET_SESSION_USER) {
            await this.restoreSession()
          }
          const action = {
            account: stakeHandlerAccount,
            name: 'transfer',
            authorization: [this.GET_SESSION_USER],
            data: {
              from: payload.sa,
              to: contractName,
              asset_ids: [data.asset_id],
              memo: ''
            }
          }
          await  this.transactPhase7({action})
        }
      } catch (e) {
        console.log(e);
      }
    },
    async phase7PotionUse({data = null}) {
      try {
        if(data) {
          const {payload} = this.GET_USER
          if(!this.GET_SESSION_USER) {
            await this.restoreSession()
          }
          const action = {
            account: stakeHandlerAccount,
            name: 'transfer',
            authorization: [this.GET_SESSION_USER],
            data: {
              from: payload.sa,
              to: contractName,
              asset_ids: [data.asset_id],
              memo: 'use'
            }
          }
          await  this.transactPhase7({action})
        }
      } catch (e) {
        console.log(e);
      }
    },
    async transactPhase7({action}) {
      try {
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.userState()
            this.$forceUpdate()
            this.$router.go(0) , 500 })
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async phase4Equip({data = {}, }) {
      try {
        const {payload} = this.GET_USER
        if(!this.GET_SESSION_USER) {
          await this.restoreSession()
        }
        const action = {
          account: stakeHandlerAccount,
          name: 'transfer',
          authorization: [this.GET_SESSION_USER],
          data: {
            from: payload.sa,
            to: contractName,
            asset_ids: [data.asset_id],
            memo: ''
          }
        }
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.userState()
            this.$forceUpdate()
            this.$router.go(0) , 500 })
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async phase9CancelDuels({action}) {
      try {
        await link.transact({action}).then(async (res) => {
          await this.userState()
          this.$forceUpdate()
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async phase9EnterDuels({action}) {
      try {
        await link.transact({action}).then(async (res) => {
          await this.userState()
          this.$forceUpdate()
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async phase9CreateDuels({action}) {
      try {
        await link.transact({action}).then(async () => {
          await this.userState()
          this.$forceUpdate()
          this.outerVisible = false
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log({...e});
      }
    },
    async transactTravelStart({quantity = [], travel_id = null}) {
      try {
        if(quantity.length && travel_id) {
          const {payload} = this.GET_USER
          if (!this.GET_SESSION_USER) {
            await this.restoreSession()
          }
          const action = {
            account: 'atomicassets',
            name: 'transfer',
            authorization: [this.GET_SESSION_USER],
            data: {
              from: payload.sa,
              to: contractName,
              asset_ids: quantity,
              memo: 'travel' + travel_id
            }
          }
          await link.transact({action}).then(async () => {
            await this.travelStatus()
          }).catch((e) => {
            throw e
          })
        }
      } catch (e) {
        console.log(e);
      }
    },
    async endTravelTransact(val) {
      try {
        if(!this.GET_SESSION_USER) {
          await this.restoreSession()
        }
        const {payload} = this.GET_USER
        let quantity = val.cost_ghcoin + '.0000 GHCOIN'

        const action = {
          account: ghcoinContractName,
          name: 'transfer',
          authorization: [this.GET_SESSION_USER],
          data: {
            from: payload.sa,
            to:contractName,
            quantity: quantity,
            memo: 'travel' + val.id
          }
        }
        await link.transact({action}).then(async () => {
          await this.travelStatus()
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log(e);
      }
    },
    async returningCanHPFromYourPocket(data) {
      try {
        const {payload} = this.GET_USER
        if(!this.GET_SESSION_USER) {
          await this.restoreSession()
        }
        const action = {
          account: contractName,
          name: 'jarout',
          authorization: [this.GET_SESSION_USER],
          data: {
            jar_asset_id: data.asset_id
          }
        }
        await link.transact({action}).then(async (res) => {
          setTimeout(async () => {
            await this.userState()
            this.$forceUpdate()
            this.$router.go(0) , 500
          })
        }).catch((e) => {
          throw e
        })
      } catch (e) {
        console.log(e);
      }
    },
    finishHandlerDuels() {
      console.log('finish transact');
      this.ACT_DUELS_CLEAR_LOGS()
      this.ACT_CLEAR_CREATOR()
      this.SOCKET_loadDuelUsers(null)
      this.$router.push({
        name: 'gamePage',
        params: {
          id: 9,
        },
        query: {
          name: 'duels'
        }
      })
    }
  }
}
</script>
