import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import resize from '@/directives/resize'
import VueCountdown from '@chenfengyuan/vue-countdown';
import {socketIO} from '@/helpers/socket/index.js'
import store from './store'

import {
  ElIcon,
  ElAvatar,
  ElBadge,
  ElLoading,
  ElRadio,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInputNumber,
  ElInput,
  ElUpload,
  ElButton,
  ElContainer,
  ElHeader,
  ElMain,
  ElFooter,
  ElDialog,
  ElRow,
  ElCol,
  ElProgress,
  ElBreadcrumb,
  ElTag,
  ElCard,
  ElImage,
  ElPagination,
  ElDropdown,
  ElAlert,
  ElNotification,
  ElDivider,
} from 'element-plus'


const app = createApp(App)

app.component(VueCountdown.name, VueCountdown);
app.directive('resize', resize)

app.use(store)
app.use(router)
app.use(ElAvatar)
app.use(ElForm)
app.use(ElFormItem)
app.use(ElUpload)
app.use(ElInput)
app.use(ElInputNumber)
app.use(ElContainer)
app.use(ElRow)
app.use(ElCol)
app.use(ElHeader)
app.use(ElMain)
app.use(ElFooter)
app.use(ElDialog)
app.use(ElButton)
app.use(ElLoading)
app.use(ElProgress)
app.use(ElBreadcrumb)
app.use(ElTag)
app.use(ElCard)
app.use(ElImage)
app.use(ElPagination)
app.use(ElDropdown)
app.use(ElRadio)
app.use(ElCheckbox)
app.use(ElBadge)
app.use(ElAlert)
app.use(ElNotification)
app.use(ElIcon)
app.use(ElDivider)
app.use(socketIO)
app.mount('#app')
