export default function authMiddleware(to, from) {
  const is_auth_user = localStorage.getItem('is_auth') ? JSON.parse(localStorage.getItem('is_auth')): null
  if(is_auth_user) {
    const {is_auth = false, user = null} = is_auth_user && is_auth_user.auth ? is_auth_user.auth: null
    if((!is_auth || !user) && to.meta.requiresAuth) {
      return { name: 'home' }
    }
    if((is_auth && user) && to.name === 'home') {
      return { name: 'gameMain' }
    }
    if((is_auth && user) && to.meta.requiresAuth) {
      return true
    }
  } else {
    return { name: 'home' }
  }

}