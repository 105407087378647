import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'
const transport = new AnchorLinkBrowserTransport()

const link = new AnchorLink({
  transport,
  chains: [
    {
      chainId: process.env.VUE_APP_CHAIN_ID,
      nodeUrl: process.env.VUE_APP_RPC_EEDPOINT
    }
  ]
})

export default link