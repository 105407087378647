<template>
  <div class="__item-social-child">
    <div class="__item-social-child_1 mr-2">
      <img class="__parent-child-soc" src="/img/app/bg/footer/social_footer.png" alt="">
      <a href="">
        <img class="__img-child-soc" src="/img/app/bg/footer/1.png" alt="">
      </a>

    </div>
    <div class="__item-social-child_2 mr-2">
      <img class="__parent-child-soc" src="/img/app/bg/footer/social_footer.png" alt="">
      <a href="">
        <img class="__img-child-soc" src="/img/app/bg/footer/2.png" alt="">
      </a>

    </div>
    <div class="__item-social-child_3">
      <img class="__parent-child-soc" src="/img/app/bg/footer/social_footer.png" alt="">
      <a href="">
        <img class="__img-child-soc" src="/img/app/bg/footer/3.png" alt="">
      </a>

    </div>
  </div>
</template>

<script>
export default {
  name: "Social.vue"
}
</script>

<style scoped>

</style>