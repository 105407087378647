<template>
  <div class="__wrap-area-hover" :id="`item_${idxArea + 1}`">
    <!--        :class="_area_id === idxArea? `item_${idxArea + 1}_bg`: ''"-->
    <area
        target="_self"
        :alt="area.title"
        :title="area.title"
        :coords="area.coords.join()"
        :shape="area.shape"
        @mouseover="areaHandlerMouseOver({area, e: $event, id: `item_${idxArea + 1}`})"
        @mouseleave="_area_id = null"
        @click="areaHandler(area)"
    >
    <el-dialog
        append-to-body
        align-center
        :show-close="false"
        v-model="outerVisible"
        class="__bg-dialog-main">
      <div class="__header-modal">
        <img class="close-modal" src="/img/app/icons/close.png" alt="" @click="outerVisible = false">
        <div class="__header-modal-wrap">
          <div class="__image">
            <img v-if="modal_data && modal_data.image" class="img-responsive w-100" :src="modal_data.image ? modal_data.image : ''" alt="">
          </div>
          <div class="__content">
            <div class="__item-block">
              <div class="__item-modal-content">
                <p v-if="modal_data && modal_data.name_resource">
                  <strong class="text-left text-captalize">{{modal_data.name_resource ? modal_data.name_resource: '-'}}</strong>
                </p>
              </div>
              <div class="__item-modal-content">
                <p v-if="modal_data && modal_data.quantity"><strong class="text-left">Extraction costs</strong><strong class="text-right">{{modal_data.quantity ? modal_data.quantity.replaceAll('.0000', ''): '0'}} Coins</strong></p>
              </div>
            </div>

            <button :style="{'opacity' : disabledMine() ? '.5': 1}" type="info" class="__btn __btn-dark" @click="mineHandler" :disabled="disabledMine()">Mine</button>
          </div>
        </div>

      </div>

    </el-dialog>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    idxArea: {
      type: Number,
      default: null
    },
    area: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      field_for_transact: ['name_resource', 'id_resource', 'quantity', 'shema', 'time_reset', 'title'],
      _area_top: 0,
      _area_left: 0,
      _area_id: null,
      outerVisible: false,
      modal_data: null
    }
  },
  computed: {
    ...mapGetters('user', ['GET_USER_LIST']),
    styleBlock() {
      return {'top': this._area_top + 'px', 'right': this._area_left + 'px'}
    },
    userList() {
      return this.GET_USER_LIST[0] || null
    }
  },
  watch: {
    outerVisible: {
      deep: true,
      handler (val) {
        if(!val) this.modal_data = null
      }
    }
  },
  methods: {
    disabledMine() {
      const time = this.timeFinish(this.area.id_resource)
      return !time || time < 0 ? false : true
    },
    calcTime(val = 0, offset = 0) {
      // val-sec offset-min
      const date_now = Date.now()
      const time_ms = val * 1000
      const offset_ms = offset * 60 * 1000
      const time = (time_ms + offset_ms) - date_now
      return !time || time < 0 ? 0 : time
    },
    timeFinish(val) {
      switch (val) {
        case 1:
          return this.calcTime(this.userList?.mined_at_1 || 0 , 240)
        case 2:
          return this.calcTime(this.userList?.mined_at_2  || 0 , 480)
        case 3:
          return this.calcTime(this.userList?.mined_at_3 || 0 , 720)
        case 4:
          return this.calcTime(this.userList?.mined_at_4 || 0 , 1440)
      }
    },
    mineHandler() {
      let _val = JSON.parse(JSON.stringify(this.modal_data))
      for (const valKey in _val) {
        if(!this.field_for_transact.includes(valKey)) delete _val[valKey]
      }
      this.$emit('mine-handler-area', _val)
    },
    areaHandlerMouseOver(val) {
      //console.log(val);
      let {pageX = 0, pageY = 0, offsetX = 0, offsetY = 0} = val.e
      let {id} = val.area
      //console.log(pageX, pageY,offsetX, offsetY);
      this._area_left = pageX
      this._area_top = pageY
      this._area_id = id
      //console.log(this._area_left, this._area_top);
      let elem = document.getElementById('' + val.id);
      let coords = elem.getBoundingClientRect();
      //console.log(coords);
      //198 1590
    },
    areaHandler(val) {
      this.modal_data = val
      if(val.modal) {
        this.outerVisible = true
      }
      if(val.link_href) {
        window.open(val.link_href, '_blank')
      }
      if(val.link) {
        this.$router.push({
          name: 'gamePage',
          params: {
            id: val.id_page,
          },
          query: {
            name: val.title
          }
        })
      }
    }
  }
}
</script>