<script>
import socket from '@/handlers/fighting/socket'
import {mapActions, mapGetters} from "vuex";
let timerId
export default {
  computed: {
    ...mapGetters('user', ['GET_USER_LIST']),
    ...mapGetters('socket', ['SOCKET_GET_loadDuelUsers']),
    ...mapGetters('auth', ['GET_USER']),
    ...mapGetters('game', ['GET_TABLE_GAME']),
    isUserDuels() {
      return this.isDuels()
    },
  },
  mixins: [socket],
  async mounted() {
    await this.$nextTick()
    timerId = setInterval(async () => {
      await this.duelsDataTable()
      if(this.GET_TABLE_GAME['duels'] && this.GET_TABLE_GAME['duels'].length && this.GET_TABLE_GAME['duels'][0].status === 1) {
        let duels = this.GET_TABLE_GAME['duels'].filter(ls => ls.owner === this.GET_USER.payload.sa)
        if(duels.length) {
          this.goDuels(duels[0].id)
        }
      }
    }, 10000)
  },
  methods: {
    ...mapActions('duels', ['ACT_DUELS_CLEAR_LOGS']),
    ...mapActions('game', ['ACT_CLEAR_CREATOR', 'ACT_GET_TABLE']),
    goDuels(id) {
      this.$router.push({
        name: 'gamePage',
        params: {
          id: 14
        },
        query: {
          name: 'fighting',
          duels_id: id
        }
      })
      clearTimeout(timerId);
    },
  }
}
</script>
