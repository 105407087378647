<script>
import {mapActions, mapGetters} from 'vuex'
import helpersDuels from '@/helpers/game/duels'
export default {
  sockets: {
    updateDuelsList: function (data) {
      console.log('duel list...')
      console.log(data);
      this.duelsDataTable()
      this.$forceUpdate()
    },
    duelStart: function (data) {
      console.log('duelStart running...')
      console.log(data);
      if(data) {
        this.$router.push({
          name: 'gamePage',
          params: {
            id: 14
          },
          query: {
            name: 'fighting',
            duels_id: data.duel_id
          }
        })
      }
      this.loadDuelUsers({duel_id: data.duel_id})
      this.$forceUpdate()
    },
    duelResultKick: function (data) {
      console.log('duel-Resul-tKick', data)
      this.resultsKickUpdate(data)
      this.$forceUpdate()
    },
    duelPotionUsed: function(data) {
      this.duelPotionUsedLogs(data)
      this.$forceUpdate()
    }
  },
  data() {
    return {
      user: {},
      enemy: {},
      kick: '1',
      block: '1',
      logList: [],
      intervalId: null,
      timer: 0,
      kicked: false,
      potions: []
    }
  },
  computed: {
    ...mapGetters('auth', ['GET_IS_AUTH', 'GET_USER']),
  },
  mixins: [helpersDuels],
  methods: {
    ...mapActions('socket', ['SOCKET_loadDuelUsers']),
    loadDuelUsers({duel_id = null}) {
      if(!duel_id) {
        duel_id = +this.$route.query.duels_id ? +this.$route.query.duels_id : null
      }
      if(duel_id) {
        this.$socket.emit('loadDuelUsers', {duel_id}, (data) => {
          if (!data.error) {
            this.SOCKET_loadDuelUsers(data)
            this.user = JSON.parse(JSON.stringify(this.prepareDuelsDataUsers({user_name: this.userSa, key: 1}) || {}))
            this.enemy = JSON.parse(JSON.stringify(this.prepareDuelsDataUsers({user_name: this.userSa, key: 2}) || {}))
          } else {
            console.warn('error', data.error);
          }
        })
      }
      this.$forceUpdate()
    },
    fixDuelCreate(val) {
      try {
        if (!this.userAuth) return
        if (!val) return
        this.$socket.emit('fixDuelCreate', {
          account: val
        }, (data) => {
          if (!data.error) {
            console.log('fixDuelCreate', data);
            //duelId
            this.loadDuelUsers({duel_id: data.duelId || null})
          } else {
            console.warn(data.error);
            return
          }
        })
        this.$forceUpdate()
      } catch (e) {
        console.log(e);
      }
    },
    fixDuelJoin({account = null, duel_id = null}) {
      if (!account || !duel_id) return
      this.$socket.emit('fixDuelJoin', {account, duel_id}, (data) => {
        if (!data.error) {
          console.log('fixDuelJoin', data);
          this.loadDuelUsers({duel_id})
          this.$forceUpdate()
        } else {
          console.warn(data);
          return false
        }
      })
    },
    fixDuelCancel({account = null, duel_id = null}) {
      if (!account && !duel_id) return
      this.$socket.emit('fixDuelCancel', {account, duel_id}, (data) => {
        if (!data.error) {
          console.log(data.error);
        } else {
          console.log(data);
          return
        }
      })
      this.SOCKET_loadDuelUsers(null)
    },
    duelKick({account, block, duel_id, kick, name}) {
      console.log(account, block, duel_id, kick, name);
      try {
        if (account && block && duel_id && kick && name) {
          let data = {account, block, duel_id: +duel_id, kick, name}
          this.$socket.emit('duelKick', data, (data) => {
            if (!data.error) {
              console.log('duel-kick',data);
              this.timer = 0
              this.waitingOpponent = true
              this.loading_center = false
              this.$forceUpdate()
            } else {
              console.log(data);
              console.log('er_kick');
              this.loading_center = false
            }
          })
        } else {
          console.warn('no data')
          this.loading_center = false
        }
      }catch (e) {
        console.log(e);
      }
    },
    prepareDuelsDataUsers({user_name = '', key = 0}) {
      if (this.duelsStatus && this.duelsStatus.length) {
        let _user = null
        if (key === 1) {
          _user = this.duelsStatus.filter(ls => ls.user === user_name)
        }
        if (key === 2) {
          _user = this.duelsStatus.filter(ls => ls.user !== user_name)
        }
        return _user.length ? _user[0] : null
      }
    },
  }
}
</script>