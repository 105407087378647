import VueSocketIO from 'vue-3-socket.io'
//for test local VUE_APP_SOCKET_URL_DEV
const isDev = process.env.NODE_ENV === 'development'
//isDev? process.env.VUE_APP_SOCKET_URL_DEV : process.env.VUE_APP_SOCKET_URL,
export const socketIO =  new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_SOCKET_URL,
  options: {
    transports: ["websocket", "polling"],
    withCredentials: false
  }
})

