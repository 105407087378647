export default {
  MUT_SETTING_LEVEL(state, data) {
    state.setting_level = data
  },
  MUT_SETTING_SLOTS(state, data) {
    state.setting_slots = data
  },
  MUT_RESET_SETTING(state) {
    state.balance = null
    state.setting_level = null
    state.setting_slots = null
  },
  MUT_SETTING_ERROR(state, data) {
    state.error = data
  }
}