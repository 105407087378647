import link from '@/helpers/auth/anchor-link'
const collection_name = process.env.VUE_APP_COLLECTION_NAME

export default {
  ACT_AVATAR({commit}, data) {
    commit('MUT_AVATAR', data)
  },
  ACT_LOGOUT({commit}) {
    commit('MUT_USER_LOGOUT')
    commit('setting/MUT_RESET_SETTING',  { root: true })
  },
  async ACT_RESTORE_SESSION({commit}) {
    try {
      await link.restoreSession(collection_name).then((session) => {
        console.log(`Session for ${session.auth} restored`)
        commit('MUT_RESTORE_SESSION', session)
      })
    } catch (e) {
      console.log(e);
    }
  },

  //anchor
  async ACT_ANCHOR_AUTH({commit}) {
    commit('MUT_AUTH_USER', false)
    try {
      const anchor = await link.login(collection_name)
      const { payload, session } = anchor
      // type = 1 - anchor type =2 - wax
      commit('MUT_AUTH_USER', {
        type: 1,
        payload,
        session: session.auth
      })
      const auth = JSON.parse(localStorage.getItem(auth) || [])
    } catch (e) {
      console.log(e);
      commit('MUT_AUTH_ERROR', true)
    }
  },
  //wax
  ACT_WAX_AUTH() {}
}