<template>
  <div class="__header-block">
    <div class="__child-wrapper">
      <el-row type="flex" :gutter="5" align="middle" justify="space-between">
        <el-col :sm="12" :xs="12" :lg="6" :xl="6">
          <div v-if="$route.name !== 'characterPage'" class="__block-auth-user">
            <img :src="'/img/app/components/badge/_u.png'" alt="">
            <div class="__block-avatar">
              <el-avatar :size="100" alt="">
                <img v-if="userAvatar" :src="userAvatar" alt="">
                <p v-else>No icon</p>
              </el-avatar>
            </div>
            <div class="__block-content">
              <div class="user-name-setting">
                <span class="text-captalize font-weight-bold">{{userData?.name || ''}}</span>
              </div>
              <userProgress
                :stamina="(userData && userData.stamina || 0) + (equipmentsAdd('stamina'))"
                :xpMaxLevel="levelHPUser()"
                :userData="userData"
              ></userProgress>
            </div>
            <div class="__block-right-setting">
              <img class="__wrap-right-img" :src="'/img/app/components/badge/_l-block.png'" alt="">
              <div class="__block-icons">
                <img :style="{'cursor': 'pointer'}" src="/img/app/components/badge/_set.png" alt="" @click.stop="characterPage">
                <el-badge :value="2" class="item" type="warning">
                  <img :style="{'cursor': 'pointer'}" src="/img/app/components/badge/_mes.png" alt="" @click.stop="goHandler()">
                </el-badge>
                <img :style="{'cursor': 'pointer'}" src="/img/app/components/badge/_aut.png" alt="" @click.stop="logout">
              </div>
            </div>
          </div>
          <div class="__block-auth-user">
            <h1 class="m-0 text-white main-menu-h" v-if="$route.name === 'characterPage'">Character</h1>
          </div>
        </el-col>
        <el-col :sm="12" :xs="12" :lg="15" :xl="15">
          <el-row type="flex" :gutter="5" align="middle" justify="space-between">
            <el-col cols="24">
              <div class="__wrap-badge-header">
                <badgeResource
                    :src="'/img/app/components/badge/_b.png'"
                    :text="'Balance'"
                    :value="numberFormat(GET_SETTING_BALANCE)"
                ></badgeResource>
                <badgeResource
                    :src="'/img/app/components/badge/_s.png'"
                    :text="'Stacking Power'"
                    :value="numberFormat(GET_USER_STACKING_POWER)"
                ></badgeResource>
                <badgeResource
                    :src="'/img/app/components/badge/_r.png'"
                    :text="'Rewards'"
                    :value="numberFormat(GET_USER_REWARDS)"
                ></badgeResource>
                <div class="__actions-block-top">
                  <button class="__btn-gold __btn __btn-success" @click="clameHandler">
                    <img src="/img/app/components/btn/_btn-succcess.png" alt="">
                    <span>Claim</span>
                  </button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {ElRow, ElCol} from 'element-plus'
import badgeResource from './components/Header/userResourse'
import userProgress from './components/Header/userProgress'
import handlerTransact from '@/handlers/transact/index'
import userState from '@/handlers/user/index'
import numberFormat from '@/mixins/numeric'
import handlersApiSchema from '@/handlers/schemaApi/index'
import {reduce} from "lodash";
export default {
  data() {
    return {
      showMenu: false
    }
  },
  components: {
    ElRow,
    ElCol,
    badgeResource,
    userProgress
  },
  computed: {
    ...mapGetters('user', ['GET_SETTING_BALANCE', 'GET_USER_STACKING_POWER', 'GET_USER_REWARDS']),
    ...mapGetters('game', ['GET_TABLE_GAME']),
    ...mapGetters('auth', ['GET_USER', 'GET_USER_AVATAR']),
    userSa() {
      return this.GET_USER && this.GET_USER.payload? this.GET_USER.payload.sa : null
      // return 'puzzlemazl11'
    },
    userAvatar() {
      return this.GET_USER_AVATAR ? process.env.VUE_APP_AXIOS_URL + '/' + this.GET_USER_AVATAR : ''
    },
    tableEquipments() {
      return this.gameTableData({table: 'equipments', owner: this.userSa})
    },
  },
  mixins: [handlerTransact, userState, handlersApiSchema],
  async mounted() {
    await this.$nextTick()
    await this.userState()
    await this.handlerCard({schema: 'equipment'})
    await this.userTable({table: 'equipments', lower_bound: false})
    await this.userTable({table: 'levels', lower_bound: false})
    this.levelHPUser()
  },
  methods: {
    ...mapActions('auth', ['ACT_LOGOUT']),
    errorHandler(val) {
      return val || true
    },
    levelHPUser() {
      let _levels_table = this.GET_TABLE_GAME['levels'] || []
      let _level_user = this.userData && this.userData.level || null
      if(_level_user && _levels_table.length) {
        const res =  _levels_table.filter(ls => ls.id === _level_user + 1)
        console.log(res);
        return res[0].xp || 0
      }
    },
    goHandler() {},
    equipmentsAdd(val) {
      if(this.tableEquipments) {
        const sum = reduce((this.tableEquipments || []).map(ls => ls[val]), (sum, n) => {
          return sum + n
        })
        return sum || 0
      } else {
        return 0
      }
    },
    gameTableData({table = '', owner = ''}) {
      if(table && owner) {
        let user_data  = (this.GET_TABLE_GAME[table] || []).filter(ls => ls.owner === owner)
        return user_data || null
      } else {
        return []
      }
    },
    characterPage() {
      this.$router.push({
        name: 'characterPage',
        params: {
          id: 11
        }
      })
    },
    logout() {
      this.ACT_LOGOUT()
      this.$router.push({
        name: 'home'
      })
      setTimeout(() => {
        this.$router.go(0)
      }, 0)
    },
    numberFormat(val) {
      return numberFormat(val)
    }
  },

}
</script>