<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters('user', ['GET_USER_LIST', 'GET_USER_RESOURCE', 'GET_USER_RESOURCE_ALL_LIST']),
    ...mapGetters('auth', ['GET_IS_AUTH', 'GET_USER']),
    ...mapGetters('game', ['GET_TABLE_GAME', 'GET_TABLE_GAME_TRAVEL']),
    userAuth() {
      return this.GET_IS_AUTH && this.GET_USER
    },
    duelsData() {
      const _data = JSON.parse(JSON.stringify(this.GET_TABLE_GAME['duels'] || []))
      return _data
    },
    duelsStartStatus() {
      return this.openStartDuels(0) || []
    }
  },
  methods: {
    ...mapActions('game', ['ACT_GET_TABLE']),
    ...mapActions('duels', ['ACT_DUELS_LOGS']),
    async duelsDataTable() {
      if(this.GET_USER) {
        const userAccount = this.GET_USER.payload.sa
        await this.ACT_GET_TABLE({
          sa: userAccount,
          table: 'duels'
        })
      }
    },
    openStartDuels(status = 0) {
      if(this.duelsData.length) {
        const start_duels = this.duelsData.filter(ls => ls.status === status).map(ls => {
          return {
            ...ls,
            logs: []
          }
        })
        return start_duels
      } else {
        return []
      }
    }
  }
}
</script>
