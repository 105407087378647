<template>
  <div class="__wrapper-user-resource">
    <div class="__badge-resourse">
      <img :src="src" alt="">
      <div class="__content-block">
        <p><span class="text-small">{{text}}</span>&nbsp;<br> <span>{{value}}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0 || '0'
    }
  }
}
</script>
