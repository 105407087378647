export default {
  MUT_USER_BALANCE(state, data) {
    state.balance = data
  },
  MUT_USER_STACKING_POWER(state, data) {
    state.user_stacking_power = data
  },
  MUT_USER_REWARDS(state, data) {
    state.user_rewards = data
  },
  MUT_USER_RESOURCE(state, data) {
    state.resource = data
  },
  MUT_USER_RESOURCE_ALL(state, data) {
    state.resource_all_list = data
  },
  MUT_USER_LIST(state, data) {
    state.user_list = data
  },
  MUT_USER_ERROR(state, data) {
    state.error = data
  },
  MUT_USER_RESOURCE_LIST_CARD(state, data) {
    state.user_character_card = data
  }
}