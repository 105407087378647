<script>
export default {
  data() {
    return {
      not_responsive_page_zoom: ['test']
    }
  },
  computed: {
    windowInnerWidth() {
      return this.windowsResize()
    }
  },
  watch: {
    windowInnerWidth: {
      deep: true,
      handler() {
        console.log(this.windowInnerWidth);
      }
    },
    '$route.name': {
      deep: true,
      handler() {
        this.defaultZoomPage()
      }
    }
  },
  methods: {
    windowsResize() {
      //console.log(window.innerWidth);
      return window.innerWidth
    },
    defaultZoomPage() {
      //console.log(window.navigator.appName);
      setTimeout(() => {
        let $elem = document.getElementById('app')
        if(this.not_responsive_page_zoom.includes(this.$route.name)) {
          document.body.style.zoom = 1
          document.body.style[`-moz-transform`] = `scale(1)`
          document.body.style['-ms-zoom'] = 1
          document.body.style['-webkit-zoom'] = 1
          document.body.style['-moz-transform'] =  `scale(1,1)`
          document.body.style['-moz-transform-origin'] = `top left`
          document.body.style['height'] = `100vh`
          return
        } else {
          if(window.innerWidth > 1300 && window.innerWidth < 1380) {
            console.log('1366');
            document.body.style.zoom = 0.71
            document.body.style[`-moz-transform`] = `scale(0.71)`
            document.body.style['-ms-zoom'] = 0.71
            document.body.style['-webkit-zoom'] = 0.71
            document.body.style['-moz-transform'] =  `scale(0.71,0.71)`
            document.body.style['-moz-transform-origin'] = `top left`
            document.body.style['height'] = `100vh`
            return
          }
          if(window.innerWidth > 1200 && window.innerWidth < 1290) {
            console.log(1280);
            document.body.style.zoom = 0.67
            document.body.style[`-moz-transform`] = `scale(0.67)`
            document.body.style['-ms-zoom'] = 0.67
            document.body.style['-webkit-zoom'] = 0.67
            document.body.style['-moz-transform'] =  `scale(0.67,0.67)`
            document.body.style['-moz-transform-origin'] = `top left`
            document.body.style['height'] = `100vh`
            return
          }
          if(window.innerWidth > 1400 && window.innerWidth < 1460) {
            document.body.style.zoom = 0.74
            document.body.style[`-moz-transform`] = `scale(0.74)`
            document.body.style['-ms-zoom'] = 0.74
            document.body.style['-webkit-zoom'] = 0.74
            document.body.style['-moz-transform'] =  `scale(0.74,0.74)`
            document.body.style['-moz-transform-origin'] = `top left`
            document.body.style['height'] = `100vh`
            console.log(1440);
            return
          }
          if(window.innerWidth > 1500 && window.innerWidth < 1555) {
            document.body.style.zoom = 0.81
            document.body.style[`-moz-transform`] = `scale(0.81)`
            document.body.style['-ms-zoom'] = 0.81
            document.body.style['-webkit-zoom'] = 0.81
            document.body.style['-moz-transform'] =  `scale(0.81,0.81)`
            document.body.style['-moz-transform-origin'] = `top left`
            document.body.style['height'] = `100vh`
            console.log(1535);
            return
          }
        }
      }, 0)
    },
    devicePixelRatio() {
      let DPR = window.devicePixelRatio
      //return ( DPR * 100).toFixed(2)
      return DPR
    },
    setDimensions({ width = 0, height = 0 }) {
      setTimeout(() => {
        console.log(this.devicePixelRatio());
      }, 500)
      if(width) {
        if(window.innerWidth > 1300 && window.innerWidth < 1380) {
          console.log('1366');
          document.body.style.zoom = 0.71
        }
        if(window.innerWidth > 1200 && window.innerWidth < 1290) {
          console.log(1280);
          document.body.style.zoom = 0.67
        }
        if(window.innerWidth > 1400 && window.innerWidth < 1460) {
          document.body.style.zoom = 0.74
          console.log(1440);
        }
        if(window.innerWidth > 1500 && window.innerWidth < 1555) {
          document.body.style.zoom = 0.81
          console.log(1535);
        }
        // if(window.innerWidth === 1920) {
        //   console.log('1920')
        //   this.img_bg =  `/img/app/bg/resp/map_1920_best.jpg`
        //   this.img_areas_map = this.areas1920_1080
        // }
        // if(window.innerWidth === 1535) {
        //   console.log('1535')
        //   this.img_bg = `/img/app/bg/resp/1535x864.jpg`
        //   this.img_areas_map = this.areas1535_864
        // }
        // if(window.innerWidth === 1440) {
        //   console.log('1440')
        //   this.img_bg = `/img/app/bg/resp/1440x900.jpg`
        //   this.img_areas_map = this.areas1440_900
        // }
        // if(window.innerWidth === 1366) {
        //   console.log('1440')
        //   this.img_bg = `/img/app/bg/resp/1366x768.jpg`
        //   this.img_areas_map = this.areas1366_768
        // }
        // if(window.innerWidth === 1280) {
        //   console.log('1440')
        //   this.img_bg = `/img/app/bg/resp/1280x720.jpg`
        //   this.img_areas_map = this.areas1280_720
        // }
      }
    }
  }
}
</script>
