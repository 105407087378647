import {has, uniq} from 'lodash'
export default {
  MUT_RESET_DATA_GROUP_DUELS(state) {
    state.duels_group_logs = []
    state.duels_group_open_data = [{}, {}]
  },
  MUT_DUELS_GROUP_OPEN(state, data) {
    state.duels_group_open = data
  },
  MUT_DUELS_GROUP_USERS(state, data) {
    state.duels_group_open_data = !data.length ? [{}, {}] : data
  },
  MUT_DUELS_GROUP_USERS_NEXT_ENEMY(state, data) {
    try {
      state.duels_group_users_next_enemy = data
    } catch (e) {
      console.log(e);
    }
  },
  MUT_DUELS_GROUP_LOGS_FINALLY(state, data) {
    state.duels_group_logs_finally = data
  },
  MUT_DUELS_GROUP_LOGS(state, data) {
    console.log(data);
    if(state.duels_group_logs.length) {
      state.duels_group_logs = state.duels_group_logs.filter(ls => ls['user'].created_up !== data['user'].created_up || ls['enemy'].created_up !== data['enemy'].created_up)
    }
    state.duels_group_logs.unshift(data)
  },
  MUT_DUELS_GROUP_USERS_UPDATE(state, {key = '', data = {}}) {
    if(state.duels_group_open_data.length > 1) {
      for(const Key in data) {
        if(has(state.duels_group_open_data[key], Key)) {
          state.duels_group_open_data[key][Key] = data[Key]
        } else {
          state.duels_group_open_data[key][Key] = data[Key]
        }
      }
    } else {
      state.duels_group_open_data[key] = data
    }
  }
}