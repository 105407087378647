import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import socket from './Socket'
import auth from './Auth'
import setting from './Seting'
import user from './User'
import game from './Game'
import phases4 from './Phases4'
import duels from './Duels'
import duelsGroups from './DuelsGroups'

const debug = process.env.NODE_ENV !== 'production'


export default createStore({
  modules: {
    auth,
    setting,
    user,
    game,
    phases4,
    socket,
    duels,
    duelsGroups
  },
  strict: debug,
  plugins: [
    createPersistedState({
      key: 'is_auth',
      paths: ['auth.is_auth', 'auth.user']
    }
  ),
    createPersistedState({
        key: 'logs',
        paths: ['duels.logs_chat']
      }
    ),
    createPersistedState({
        key: 'duels_user_champion',
        paths: ['duels.duels_user_champion']
      }
    ),
    //duels_user_champion
    createPersistedState({
        key: 'creator_duels',
        paths: ['game.creator_duels']
      }
    ),
    // createPersistedState({
    //     key: 'socket_duels',
    //     paths: ['socket.duels']
    //   }
    // )
    createPersistedState({
        key: 'logs_groups',
        paths: ['duelsGroups.duels_group_logs']
      }
    ),
    createPersistedState({
        key: 'users_groups',
        paths: ['duelsGroups.duels_group_open_data']
      }
    ),
  ],
})
