import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  table: {},
  table_travel: {},
  creator_duels: null,
  error: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}