import rpc from '@/helpers/auth/rpc'
const contractName = process.env.VUE_APP_CONTRACT_NAME
export default {
  async ACT_GET_TABLE_HEALTH({commit}, {sa = null, table = null}) {
    try {
      if(sa && table) {
        let rpc_data = {
          json: true,
          code: contractName,
          scope: contractName,
          table: table || '',
          index_position: 2,
          key_type: 'name',
          lower_bound: sa,
          upper_bound: sa,
          limit: 10,
          reverse: false,
          show_payer: false,
        }
        const list = await rpc.get_table_rows(rpc_data)
        commit('MUT_USER_BANK_HEALTH', list.rows || [])
      }
    } catch (e) {
      console.log(e);
      commit('MUT_ERROR_GAME', e)
    }
  },
  ACT_DUELS_KICK_RESULTS({commit}, data) {
    commit('MUT_DUELS_KICK_RESULTS', data)
  },
  ACT_DUEL_ID({commit}, data) {
    commit('MUT_DUEL_ID', data)
  },
  ACT_DUELS_FINAL_CORRECT_INDEX({commit}) {
    commit('MUT_FINAL_LOG')
  },
  ACT_DUELS_LOGS({commit}, data) {
    commit('MUT_DUELS_LOGS', data)
  },
  ACT_DUELS_CLEAR_LOGS({commit}) {
    commit('MUT_DUELS_LOGS_CLEAR')
    commit('MUT_DUEL_ID', null)
  },
  ACT_DUELS_USER_CHAMPION({commit}, data) {
    commit('MUT_DUELS_USER_CHAMPION', data)
  }
}