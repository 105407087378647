<template>
  <div class="__main-layout" v-resize="setDimensions" >
    <img :src="img_bg" class="__image-map" usemap="#image-map" alt="">
    <map name="image-map">
        <vArea
            v-for="(area, idx) in img_areas_map"
            :key="idx"
            :area="area"
            :idxArea="idx"
            @mine-handler-area="transactArea($event)"
        ></vArea>
      </map>
      <el-container class="__wrapper-main-container">
        <div class="__wrap-block-header">
          <el-header>
            <vHeader></vHeader>
          </el-header>
        </div>
        <keep-alive>
          <router-view/>
        </keep-alive>
        <div class="__wrap-block-footer">
          <el-footer class="w-100">
            <vFooter></vFooter>
          </el-footer>
        </div>
      </el-container>
  </div>
</template>
<script>
import responsiveHelpers from '@/helpers/responsive'
import vArea from '@/components/Main/map/vArea'
import areas from '@/helpers/data/area'
import areas1920_1080 from '@/helpers/data/areas1920_1080'
import areas1535_864 from '@/helpers/data/areas1535_864'
import areas1440_900 from '@/helpers/data/areas1440_900'
import areas1366_768 from '@/helpers/data/areas1366_768'
import areas1280_720 from '@/helpers/data/areas1280_720'
import vHeader from '@/components/Main/vHeader'
import vFooter from '@/components/Main/vFooter'
import transactHandlers from '@/handlers/transact/index'
import userState from '@/handlers/user/index'
export default {
  props: {
    resize: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      img_bg: '/img/app/bg/resp/map_1920_best.jpg',
      img_areas_map: []
    }
  },
  components: {
    vArea,
    vHeader,
    vFooter,
  },
  computed: {
    areas() {
      return areas
    },
    areas1920_1080() {
      return areas1920_1080
    },
    areas1535_864() {
      return areas1535_864
    },
    areas1440_900() {
      return areas1440_900
    },
    areas1366_768() {
      return areas1366_768
    },
    areas1280_720() {
      return areas1280_720
    }
  },
  mixins: [responsiveHelpers, transactHandlers, userState],
  watch: {
    devicePixelRatio: {
      imediate: true,
      deep: true,
      handler() {
        console.log(this.devicePixelRatio);
      },

    },
    resize: {
      deep: true,
      handler(val) {
        //console.log(val);
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    console.log('window.innerWidth', window.innerWidth);
    this.defaultZoomPage()
    this.img_bg =  `/img/app/bg/resp/map_1920_best.jpg`
    this.img_areas_map = this.areas1920_1080
    this.setDimensions({ width: 0, height: 0 })
  },

}
</script>