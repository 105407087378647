<template>
  <div class="">
    <vue-countdown
        @start="startTimer"
        @end="endTimer"
        :emit-events="false"
        auto-start
        :time="time"
        v-slot="{ days, hours, minutes, seconds }">
      {{textLabel}} {{ daysShow ? days || '00' : '' }} {{daysShow ? ':' : ''}} {{hours || '00'}} : {{ minutes || '00' }} : {{ seconds || '00' }}
    </vue-countdown>
  </div>
</template>
<script>
export default {
  props: {
    daysShow: {
      type: Boolean,
      default: true
    },
    textLabel: {
      type: String,
      default: ''
    },
    time: {
      type: Number,
      default: 0
    }
  },
  methods: {
    endTimer() {
      this.$emit('end-timer', 2)
    },
    startTimer() {
      this.$emit('start-timer', 1)
    }
  }
}
</script>