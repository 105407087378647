<template>
  <div class="__game-layout" :class="`__game-page-${$route.params.id}`" :style="{'background-image': `url(${bg_image})`}">
    <el-container>
      <el-header>
        <vHeader v-if="!isShowHeaderIDPage"></vHeader>
        <vBreadcrumbs v-if="!isShowHeaderCrumbs"></vBreadcrumbs>
      </el-header>
      <el-main>
        <router-view @router-handler-watch="routerHandlerWatch"/>
      </el-main>
      <el-footer v-if="!isShowFooterIDPage">
        <vFooter ></vFooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import responsiveHelpers from '@/helpers/responsive'
import vHeader from '../components/Main/vHeader'
import vFooter from '../components/Main/vFooter'
import vBreadcrumbs from '../components/Main/components/Header/vBreadcrumbs'
export default {
  data() {
    return {
      bg_image: ''
    }
  },
  computed: {
    isShowFooterIDPage() {
      return [7, 8,9,10,11,12,13,14,15].includes(+ this.$route.params.id)
    },
    isShowHeaderIDPage() {
      return [14, 15].includes(+ this.$route.params.id)
    },
    isShowHeaderCrumbs() {
      return [14, 15].includes(+ this.$route.params.id)
    }
  },
  components: {
    vHeader,
    vFooter,
    vBreadcrumbs
  },
  async mounted() {
    await this.$nextTick()
    this.defaultZoomPage()
  },
  mixins: [responsiveHelpers],
  methods: {
    routerHandlerWatch(val) {
     try {
       this.bg_image = this.bgImage(val)
     } catch (e) {
       console.log(e);
     }
    },
    bgImage(val) {
      switch (+val) {
        case 0:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 1:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 2:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 3:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 4:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 5:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 6:
          return `/img/app/bg/layouts/townHall/_main.jpg`
        case 7:
          return `/img/app/bg/layouts/travel/_main.jpg`
        case 8:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 9:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 10:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 11:
          return `/img/app/bg/layouts/character/bg.png`
        case 12:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 13:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 14:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        case 15:
          return `/img/app/bg/layouts/fighting/_main.jpg`
        default:
          return ''
      }
    }
  }
}
</script>