import {uniqBy} from 'lodash'
export default {
  MUT_ERROR_GAME(state, data) {
    state.error = data
  },
  MUT_USER_BANK_HEALTH(state, data) {
    state.health = data
  },
  MUT_DUELS_LOGS(state, data) {
    console.log(state.duel_id);
    if(data) {
      state.logs_chat.unshift(data)
    }
    state.logs_chat = (state.logs_chat || []).filter(Boolean)
    //state.logs_chat = uniqBy(state.logs_chat || [], 'user.created_up')
    //state.logs_chat = uniqBy(state.logs_chat || [], 'enemy.id')
    state.logs_chat = (state.logs_chat || []).filter(ls => +ls['enemy'].duel_id === +state.duel_id && +ls['user'].duel_id === +state.duel_id)
  },
  MUT_FINAL_LOG(state) {
    if(state.logs_chat.length > 1) {
      const delete_idx = state.logs_chat.splice(1, 1)
      console.log('final_delete',delete_idx);
      state.logs_chat.splice(1, 1)
    }
  },
  MUT_DUEL_ID(state, data) {
    state.duel_id = data
  },
  MUT_DUELS_KICK_RESULTS(state, data) {
    state.kick_results = data
  },
  MUT_DUELS_LOGS_CLEAR(state) {
    state.logs_chat = []
    state.duels_user_champion = null
  },
  MUT_DUELS_USER_CHAMPION(state, data) {
    state.duels_user_champion = data
  }
}