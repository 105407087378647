<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters('user', ['GET_USER_LIST']),
    ...mapGetters('auth', ['GET_USER']),
    ...mapGetters('duelsGroups', ['GET_DUELS_GROUP_OPEN']),
    userList() {
      return this.GET_USER_LIST.length ? this.GET_USER_LIST[0]: null
    },
  },
  watch: {
    userList: {
      deep: true,
      immediate: true,
      async handler() {
        await this.checkIsDuelsGroup()
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    setTimeout(async () => {
      await this.checkIsDuelsGroup()
    }, 500)

  },
  methods: {
    ...mapActions('duelsGroups', ['ACT_DUELS_GROUP_OPEN', 'ACT_DUELS_GROUP_OPEN_CHECK', 'ACT_RESET_DATA_GROUP_DUELS']),
    async checkIsDuelsGroup() {
      if (this.userList) {
        if (+ this.userList.fight_type === 2 || + this.userList.fight_type === 1) {
          console.log('Проверка по fight_type', this.userList.fight_type);
          const duels_group = await this.ACT_DUELS_GROUP_OPEN_CHECK({
            lower_bound: this.userList.fight_id,
            upper_bound: this.userList.fight_id,
            limit: 1
          })
          console.log('duels_group', duels_group);
          setTimeout(() => {
            if (duels_group.length && (duels_group[0].status === 1 || duels_group[0].status === 2)) {
              console.log('Проверка по duels_group', duels_group.length && duels_group[0].status);
              this.goGroupsDuels({
                group_id: this.userList.fight_id
              })
            } else {
              console.log('Не проходит duels_group.length && duels_group[0].status = 1');
              this.resetDataGroupUsers()
            }
          }, 500)
        } else {
          console.log('Не проходит this.userList.fight_type');
          this.resetDataGroupUsers()
        }
      }
    },
    goGroupsDuels(data) {
      console.log(data);
      try{
        this.$router.push({
          name: 'gamePage',
          params: {
            id: 15
          },
          query: {
            name: 'fightingGroup',
            duels_group_id: data.group_id
          }
        })
      } catch (e) {
        console.log(e);
      }
    },
    resetDataGroupUsers() {
      this.ACT_RESET_DATA_GROUP_DUELS()
    },
  }
}
</script>
