<template>
  <div  :style="{'top': !isShowHeaderIDPage? '200px' : '20px', 'left': '30px'}" class="__back-block" @click="goBack">
    <img src="/img/app/icons/arrow-down.png" alt="">
    <p>Back</p>
  </div>
</template>

<script>
export default {
  computed: {
    isShowHeaderIDPage() {
      return [15].includes(+ this.$route.params.id)
    }
  },
  methods: {
    goBack() {
      let back_choice = [9,10,11,12,13,14]
      if(this.$route.params.id && back_choice.includes(+this.$route.params.id)) {
        this.$router.push({
          name: 'gamePage',
          params: {
            id: 8
          },
          query: {
            name: 'choice'
          }
        })
        return
      }
      this.$router.push({
        name: 'gameMain'
      })
      return
    }
  }
}
</script>
<style lang="scss">
.__back-block {
  cursor: pointer;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;
  p {
    margin: 0;
    padding-left: 10px;
    color: #ffffff;
    font-weight: 100;
    font-size: 20px;
    font-family: 'Futura PT Book', sans-serif;
  };
}
.el-breadcrumb {
  background-color: rgba(0, 0, 0, 0.98);
  font-family: 'Futura PT Book';
  font-weight: 900;
  font-size: 13px;
  padding: 10px;
  position: absolute;
  left: 0;
  top: 160px;
  border-radius: 4px;
  span {
    color: #ffffff;
  }
}
</style>