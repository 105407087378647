import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  error: false,
  health: null,
  kick_results: [],
  logs_chat: [],
  duel_id: null,
  duels_user_champion: null
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}