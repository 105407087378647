<template>
  <div class="__wrapper-main-block">
    <div class="__beta-badge">
      <p class="font-Futura-PT-Book-normal text-white">Beta v1.0</p>
    </div>
    <keep-alive>
      <component :is="layout"></component>
    </keep-alive>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import authLayout from './layouts/Auth'
import mainLayout from './layouts/Main'
import errorLayout from './layouts/Error'
import gameLayout from './layouts/Game'
import 'element-plus/es/components/col/style/css'
import 'element-plus/es/components/row/style/css'
import goDuelsHelpers from '@/helpers/goDuels'
import goGroupsDuelsHelpers from '@/helpers/goGroupsDules.vue'
export default {
  sockets: {
    connect: function () {
      console.info('socket connected')
    },
  },
  data() {
    return {
      loading: true
    }
  },
  components: {
    authLayout,
    mainLayout,
    errorLayout,
    gameLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'authLayout')
    }
  },
  mixins: [goDuelsHelpers, goGroupsDuelsHelpers],
  mounted() {
    console.log(process.env.NODE_ENV);
    setTimeout(async () => {
      this.loading = false
    }, 1000)
  }
}
</script>
<style lang="scss">
@import '@/assets/style/main.scss';
</style>
