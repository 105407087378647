import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  setting_level: null,
  setting_slots: null,
  error: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}